import axios from "axios";
import UtilService from "./UtilService";
import {
    isBefore,
    format,
    parseISO,
    formatDistanceToNow
} from 'date-fns';
import LoginService from "../Public/Pages/Authentication/SignIn/Login.service";
import UtilServiceReact from "./UtilServiceReact";

const axiosInstance = axios.create();

const ApiTokenService = (navigate?: any) => {
    const apiUrl = process.env.REACT_APP_NODE_API_URL;

    const logOut = () => {
        localStorage.setItem('accessToken', "");
        localStorage.setItem('localUser', "");
        localStorage.setItem('apiToken', "");
        localStorage.setItem('passwordLastChanged', "");
        if (navigate) {
            navigate(`${process.env.PUBLIC_URL}/authentication/login`);
        } else {
            window.location.href = `${process.env.PUBLIC_URL}/authentication/login`
        }
    }

    const validatePasswordChanged = async (localPasswordLastChanged: any) => {
        const loggedUser = UtilService.getLocalUserData();
        const utilServiceReact = UtilServiceReact();
        const { checkUserEmail } = LoginService();

        await checkUserEmail(loggedUser?.email).then((res: any) => {
            if (res?.success && res.passwordLastChanged && localPasswordLastChanged) {
                if (res.passwordLastChanged && localPasswordLastChanged) {
                    const serverDate = new Date(res.passwordLastChanged).toISOString();
                    const localDate = new Date(localPasswordLastChanged).toISOString();
                    // Check if server date is greater than local date
                    if (serverDate > localDate) {
                        logOut();
                    }
                }
            }
        })
    }

    const apiToken = async () => {
        const token = await fetchAPIToken();
        if (token) {
            localStorage.setItem('apiToken', token);
            return token;
        }
    }

    async function fetchAPIToken() {
        try {
            const response = await axiosInstance.get(`${apiUrl}/generate-token`);
            return response.data.token;
        } catch (error) {
            console.log(error);
        }
    }

    axiosInstance.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response?.status === 401) {
                logOut();
                console.log('Token expired or invalid. Redirecting to login...');
            }
            return Promise.reject(error);
        }
    );

    const handleApiError = (error: any) => {
        if (error?.response?.data && 403 === error?.response?.status) {
            return error?.response?.data;
        } else {
            console.log(error);
            return 'Something went wrong. Please try again.';
        }
    };

    const renewApiToken = async () => {
        try {
            await apiToken();
            const resApiToken = localStorage.getItem('apiToken');
            if (resApiToken) {
                const headers = UtilService.getHeadersApiToken();
                return headers;
            }
        } catch (error) {
            console.log("Trying to renewApiToken", error);
        }
        return null;
    }

    const getLocalIpAddressInfo = async () => {
        try {
            const response = await axiosInstance.get('https://ipinfo.io/json?token=b5e55cf0650fe5');
            return response.data;
        } catch (error) {
            console.log('Error fetching IP data', error);
        }
        return null;
    }

    return {
        logOut,
        apiToken,
        axiosInstance,
        renewApiToken,
        handleApiError,
        getLocalIpAddressInfo,
        validatePasswordChanged
    }

}

export default ApiTokenService;